@import '@/styles/variables.sass'
@import '~vuetify/src/styles/styles.sass'























































@import "@/mixins/styleset.sass"

.snap-wrapper
  //scroll-snap-type: y proximity
  height: 100vh
  width: 100%


.nv-zuwendungen
  background-image: url("../assets/istockphoto-1061633278-1024x1024.jpg")
  background-position: right
  background-size: cover
  height: 60vh

  li
    font-size: large


