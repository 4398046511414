@import '@/styles/variables.sass'
@import '~vuetify/src/styles/styles.sass'












































































































































@import "@/mixins/styleset.sass"

.nv-executive
  padding-top: 50px

