@import '@/styles/variables.sass'
@import '~vuetify/src/styles/styles.sass'















































































@import "@/mixins/styleset.sass"

.nv-start
  background-position: right
  background-size: cover
  height: 100vh

  display: flex
  flex-direction: row

  .nv-title
    padding-top: 100px

  h1, h2
    text-shadow: 0px 1px 15px black


