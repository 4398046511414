@import '@/styles/variables.sass'
@import '~vuetify/src/styles/styles.sass'





















































.nv-bichmann
  background-color: #171927
  padding-top: 50px
  min-height: 60vh

  p, h2
    color: #7a6c6c !important



