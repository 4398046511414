@import url(/src/assets/fonts/Newsreader/Newsreader-Bold.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-BoldItalic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-ExtraBold.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-ExtraBoldItalic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-ExtraLight.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-ExtraLightItalic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-Italic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-Light.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-LightItalic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-Medium.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-MediumItalic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-Regular.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-SemiBold.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-SemiBoldItalic.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Black.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Bold.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-ExtraBold.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-ExtraLight.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Light.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Medium.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Regular.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-SemiBold.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Thin.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-Bold.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-BoldItalic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-ExtraBold.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-ExtraBoldItalic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-ExtraLight.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-ExtraLightItalic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-Italic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-Light.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-LightItalic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-Medium.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-MediumItalic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-Regular.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-SemiBold.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-SemiBoldItalic.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Black.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Bold.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-ExtraBold.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-ExtraLight.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Light.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Medium.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Regular.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-SemiBold.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Thin.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-Bold.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-BoldItalic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-ExtraBold.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-ExtraBoldItalic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-ExtraLight.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-ExtraLightItalic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-Italic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-Light.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-LightItalic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-Medium.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-MediumItalic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-Regular.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-SemiBold.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-SemiBoldItalic.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Black.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Bold.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-ExtraBold.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-ExtraLight.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Light.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Medium.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Regular.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-SemiBold.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Thin.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-Bold.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-BoldItalic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-ExtraBold.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-ExtraBoldItalic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-ExtraLight.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-ExtraLightItalic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-Italic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-Light.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-LightItalic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-Medium.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-MediumItalic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-Regular.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-SemiBold.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-SemiBoldItalic.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Black.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Bold.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-ExtraBold.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-ExtraLight.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Light.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Medium.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Regular.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-SemiBold.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Thin.ttf);
@import url(https://collette-hecht-stiftung.de/src/assets/fonts/Catamaran/Catamaran-Regular.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-Bold.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-BoldItalic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-ExtraBold.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-ExtraBoldItalic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-ExtraLight.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-ExtraLightItalic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-Italic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-Light.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-LightItalic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-Medium.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-MediumItalic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-Regular.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-SemiBold.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-SemiBoldItalic.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Black.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Bold.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-ExtraBold.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-ExtraLight.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Light.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Medium.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Regular.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-SemiBold.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Thin.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-Bold.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-BoldItalic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-ExtraBold.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-ExtraBoldItalic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-ExtraLight.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-ExtraLightItalic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-Italic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-Light.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-LightItalic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-Medium.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-MediumItalic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-Regular.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-SemiBold.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-SemiBoldItalic.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Black.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Bold.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-ExtraBold.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-ExtraLight.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Light.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Medium.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Regular.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-SemiBold.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Thin.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-Bold.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-BoldItalic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-ExtraBold.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-ExtraBoldItalic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-ExtraLight.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-ExtraLightItalic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-Italic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-Light.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-LightItalic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-Medium.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-MediumItalic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-Regular.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-SemiBold.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-SemiBoldItalic.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Black.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Bold.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-ExtraBold.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-ExtraLight.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Light.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Medium.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Regular.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-SemiBold.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Thin.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-Bold.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-BoldItalic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-ExtraBold.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-ExtraBoldItalic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-ExtraLight.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-ExtraLightItalic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-Italic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-Light.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-LightItalic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-Medium.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-MediumItalic.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-Regular.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-SemiBold.ttf);
@import url(/src/assets/fonts/Newsreader/Newsreader-SemiBoldItalic.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Black.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Bold.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-ExtraBold.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-ExtraLight.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Light.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Medium.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Regular.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-SemiBold.ttf);
@import url(/src/assets/fonts/Catamaran/Catamaran-Thin.ttf);
#app{font-family:"Open Sans",sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;text-align:left;scroll-behavior:smooth;color:#000}#app h1,#app h2,#app h3,#app h4{font-family:"Newsreader",serif}#app h1{font-size:6vw;font-weight:normal}#app h2{font-size:4vw;font-weight:normal}img{width:100%}*::-webkit-scrollbar{display:none}*{-ms-overflow-style:none;scrollbar-width:none}
.nv-contact{padding-top:50px}.nv-contact .cryptedmail:after{content:attr(data-name) "@" attr(data-domain) "." attr(data-tld)}
.nv-projects[data-v-023cc2cb]{padding:0 !important;margin:0}.nv-projects .nv-text-highlight[data-v-023cc2cb]{text-shadow:0px 1px 15px #000}
.nv-quote-text[data-v-e173b2e2]{-webkit-hyphens:auto;-ms-hyphens:auto;hyphens:auto;text-align:justify}.nv-quote[data-v-e173b2e2]{position:relative;font-family:"Catamaran",sans-serif;font-size:20rem;display:flex;line-height:0;opacity:.3}.nv-left-quote[data-v-e173b2e2]{justify-content:flex-start;transform:translate(-130px, 80px)}.nv-right-quote[data-v-e173b2e2]{justify-content:flex-end;transform:translate(130px, 110px)}
.nv-start{background-position:right;background-size:cover;height:100vh;display:flex;flex-direction:row}.nv-start .nv-title{padding-top:100px}.nv-start h1,.nv-start h2{text-shadow:0px 1px 15px #000}
.nv-executive{padding-top:50px}
.nv-bichmann{background-color:#171927;padding-top:50px;min-height:60vh}.nv-bichmann p,.nv-bichmann h2{color:#7a6c6c !important}
.snap-wrapper{height:100vh;width:100%}.nv-zuwendungen{background-image:url(../../../src/assets/img/istockphoto-1061633278-1024x1024.2483537d.jpg);background-position:right;background-size:cover;height:60vh}.nv-zuwendungen li{font-size:large}

/*# sourceMappingURL=app.fe528ef4.css.map*/