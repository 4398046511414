@import '@/styles/variables.sass'
@import '~vuetify/src/styles/styles.sass'






















































































































































































































.nv-contact
  padding-top: 50px

  .cryptedmail
    &:after
      content: attr(data-name) "@" attr(data-domain) "." attr(data-tld)


