@import '@/styles/variables.sass'
@import '~vuetify/src/styles/styles.sass'










































































@import "@/mixins/styleset.sass"


.nv-projects
  padding: 0 !important
  margin: 0

  .nv-text-highlight
    text-shadow: 0px 1px 15px black


